export default {
  common: {
    title: 'Affiliate System',
    logout: 'Logout',
    changePassword: 'Change Password',
    login: 'Login',
    signup: 'Signup',
    username: 'Username',
    loginnow: 'Login Now',
    password: 'Password',
    apply: 'Apply',
    verificationcode: 'Verification Code',
    verification_title: 'Security Check, Please click steps by steps：',
    verification_drag_title: 'Security Check, Please drag to verify',
    verify_success: 'Verify Success',
    refresh: 'Refresh',
    submit: 'Submit',
    register_affi: 'Register Now',
    back_login: 'Back Login',
    contact_us: 'Contact Us',
    affiliateaccountcanonlycontainnumchar:
      'The affiliate account can only contain underscores (_), numbers, and letters.',
    confirmpassword: 'Confirm password.',
    affiliateaccount: 'Affiliate Account Name',
    forgetpass: 'Forget Password.',
    zhuanshukefufuwu: 'Exclusive Customer Service',
    askus: 'Request',
    download: 'Download',
    copy: 'Copy',
    copied: 'Copied',
    email: 'Email',
    qq: 'QQ',
    skype: 'Skype',
    paopao: 'Bubble',
    zalo: 'Zalo',
    telegram: 'Telegram',
  },
  depositStatus: {
    SUCCESS: 'Success',
    SUPPLEMENT_SUCCESS: 'Supplement Success',
    CLOSED: 'Closed',
    PENDING: 'Pending',
  },
  withdrawStatus: {
    ALL: 'All',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    APPLY: 'Applying',
    STEP_1: 'Under Review',
    STEP_2: 'To Be Paid',
    STEP_3: 'Payment On Going',
    AUTOPAY: 'Automatic Payment',
    PENDING: 'Suspend',
    REJECT: 'Reject',
    SENDING: 'Sending',
    WAITING_CALLBACK: 'Waiting Callback',
    PAYING: 'Paying',
    WAITING_AUTO_PAY: 'Waiting Auto Payment',
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  questions: {
    mothersName: "What is your mother's name?",
    mothersBirthday: "When is your mother's birthday?",
    dadsName: "What is your father's name?",
    dadsBirthday: "When is your father's birthday?",
    spouseBirthday: "When is your spouse's birthday?",
    cityBorn: 'What city were you born in?',
    highSchool: 'What high school did you attend?',
    elementarySchool: 'Whatwas the name of your elementary school?',
    firstPetName: 'What was the name of your first pet?',
    firstCar: 'What is the make of your first car?',
  },
  gameType: {
    SLOT: 'SLOT',
    LIVE: 'LIVE',
    FISH: 'FISH',
    SPORT: 'SPORT',
    ESPORT: 'ESPORT',
    POKER: 'POKER',
    LOTTERY: 'LOTTERY',
    MINIGAME: 'MINIGAME',
  },
  result: {
    WIN: 'WIN',
    LOSS: 'LOSS',
    DRAW: 'DRAW',
  },
  betStatus: {
    UNSETTLED: 'UNSETTLED',
    SETTLED: 'SETTLED',
    CANCEL: 'CANCEL',
  },
  transferType: {
    DEPOSIT: 'Transfer from Balance',
    COMMISSION: 'Transfer from Commission Balance',
  },
  creditFlowType: {
    Withdraw: 'Withdraw',
    Transfer: 'Transfer',
    Dividen: 'Dividen',
    Deposit: 'Deposit',
    Agent_Transfer: 'Agent Transfer',
    Agent_Deposit: 'Agent Deposit',
    COMMISSION_WALLET_WITHDRAWAL: 'Withdraw',
    COMMISSION_WALLET_AFFILIATE_DEPOSIT: 'Affiliate Deposit',
    COMMISSION_WALLET_SETTLEMENT: 'Comission Settlement',
    DEPOSIT_WALLET_DEPOSIT: 'Deposit',
    DEPOSIT_WALLET_AFFILIATE_DEPOSIT: 'Affiliate Deposit',
    DEPOSIT_WALLET_AMOUNT_ADJUST: 'Manual Adjust',
  },
  referralLink: {
    affiliateWebPlatformLink: 'PC Platform Affiliate Link',
    affiliateH5PlatformLink: 'H5 Platform Affiliate Link (Recommended)',
    affiliateWXShortLink: 'Anti Block Url (Wechat)',
    affiliateQQShortLink: 'Anti Block Url (QQ)',
    affiliateZALOShortLink: 'Anti Block Url (Zalo)',
    affiliateLongLink: 'Normal Url',
    affiliateWXQRLink: 'Anti Block QRCode (Wechat)',
    affiliateQQQRLink: 'Anti Block QRCode (QQ)',
    affiliateZALOQRLink: 'Anti Block QRCode (Zalo) ',
    affiliateLongQRLink: 'Normal Link QRCode',
    affiliateDownloadQRtoLocal: 'Download',
    affiliateScanMe: 'Scan and View in Mobile',
    copiedWXShortUrl: 'Successfully Copied Wechat Anti Block Url',
    copiedQQShortUrl: 'Succesffully Copied QQ Anti Block Url',
    copiedZALOShortUrl: 'Succesffully Copied Zalo Anti Block Url',
    copiedLink: 'Copied Successfully',
    downloadQRCode: 'Download QR Code',
    affiliateShortLink: 'Short Domain',
  },
  commissionInfo: {
    commissionInfo: 'Commission Info',
    affiliateCommissionInfo: 'Affiliate Commission Info',
    commissionCalculator: 'Commission Calculator',
    monthlyTotalEffectiveTurnover: 'Total Monthly Winloss',
    pleaseInsertMonthlyTotalEffectiveTurnover:
      'Please Enter Total Monthly Winloss',
    monthlySettlement: 'Monthly Settlement',
    pleaseInsertMonthlySettlement: 'Please Enter Montly Settlement',
    monthlyDividen: 'Monthly Bonuse',
    pleaseInsertMonthlyDividen: 'Please Enter Monthly Bonus',
    platformFee: 'Platform Fee',
    monthlyTotalDeposit: 'Total Member Monthly Deposit',
    pleaseInsertMonthlyTotalDeposit:
      'Please Enter Total Member Monthly Deposit',
    monthlyTotalWithdrawal: 'Total Member Monthly Withdrawal',
    pleaseInsertMonthlyTotalWithdrawal:
      'Please Enter Member Monthly Withdrawal',
    totalCommissionAbleClaim: 'Total Monthly Commission Able Claim',
    calculate: 'Calculate',
    commissionCalculatorNote:
      'Note: The simulator calculates based on the average rebate and bonus estimates, and the venue selection defaults to the situation where all members participate in the game at the same venue. The results are for reference only.',
    commissionRateInfo: 'Commission Rate Info',
    commissionRateDescription: 'Affiliate commission rate is determined by monthly win loss and number of active member, when both conditions are met, affiliate will get the corresponding commission rate.',
    activeMemberRequirement: 'Number of active members: Deposit for the month >= 100 or valid bet >= 500;',
    level: 'Level',
    companyMonthlyProfit: 'Company Monthly Profit',
    activeNumberRequirement: 'New/ Active Count Minimum Requirement',
    commissionRate: 'Commission Rate',
    commissionCalculateFormula: 'Commission Calculation',
    commissionFormula: 'Commission = Net Profit After Correction * Commission Rate + Commission Adjustment + Commission Supplement; ',
    correctionNetProfitFormula: 'Net Profit After Correction = Monthly Net Profit + Last Month Balance; ',
    monthlyNetProfitFormula: 'Monthly Net Profit = Monthly Total Win Loss - Bonus - Rebate - Platform Fee - Account Adjustment + Supplement Win Loss - Deposit Withdrawal Processing Fee; ',
    bonusFormula: 'Bonus = Total Amount of Bonus received by affiliate downline in current month; ',
    rebateFormula: 'Rebate = Total Amount of Rebate received by affiliate downline in current month; ',
    platformFeeFormula: 'Platform Fee = Total Win Loss of each Platform * Platform Fee Rate, But only when there is profit in that month, platform will charge platform fee; ',
    adjustmentFormula: 'Account adjustment = When member rollover is abnormal, the site admin will make account adjustments to the member;',
    supplementWinLossFormula: 'Supplement Win Loss = Last Month Real-time Data Net Profit - Net Profit of commission distributed last month; ',
    depositFeeFormula: 'Deposit Fee = Downline Deposit Amount * Deposit Fee Rate + Affiliate Deposit Amount * Deposit Fee Rate; ',
    withdrawalFeeFormula: 'Withdrawal Fee = Downline Withdrawal Amount * Withdrawal Fee Rate; ',
    commissionAdjustmentNote: 'Commission Supplement: After the commission is released for the month, commission supplement will made based on relevant activities the affiliate participated in. ',
    commissionNote: 'Note: Please remember that anyone who uses dishonest methods to defraud commissions will have their account permanently frozen and the partnership terminated. All commissions will not be paid. ',
  },
  sortType: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
  rebateStatus: {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN PROGRESS',
    DISTRIBUTED: 'DISTRIBUTED',
    CANCEL: 'CANCEL',
  },
  fields: {
    account: 'Account',
    accountInfo: 'Account Information',
    activeMember: 'Active Member',
    activePlayer: 'Active Player',
    activeUsers: 'Active Users',
    add: 'Add',
    addBankCard: 'Add Bank Card',
    addVirtualCard: 'Add Virtual Card',
    adjust: 'Adjust',
    adjustAmount: 'Adjust Amount',
    adjustment: 'Adjustment',
    adjustReason: 'Adjust Reason',
    adjustType: 'Adjust Type',
    affiliate: 'Affiliate',
    affiliateAccount: 'Affiliate Account',
    affiliateCode: 'Affiliate Code',
    affiliateDeposit: 'Deposit',
    affiliateInfo: 'Affiliate Info',
    affiliateLevel: 'Affiliate Level',
    affiliateStatus: 'Affiliate Status',
    affiliateWithdraw: 'Bank Withdraw',
    affiliateWithdrawRecord: 'Bank Withdraw Record',
    amount: 'Amount',
    amountOfFirstDeposit: 'Amount of First Deposit',
    answerOne: 'Answer One',
    answerTwo: 'Answer Two',
    answerThree: 'Answer Three',
    answerSecurityQuestion: 'Answer All the Security Question',
    appLink: 'Application Download Link',
    balance: 'Balance',
    bank: 'Bank',
    bankCard: 'Bank Card',
    bankId: 'Bank ID',
    bankName: 'Bank Name',
    bankCode: 'Bank Code',
    bet: 'Bet',
    betMembers: 'Bet Members',
    betRecord: 'Bet Record',
    betRecordDetails: 'Bet Record Details',
    betTime: 'Bet Time',
    bind: 'Bind',
    bindAccountList: 'Bind Account List',
    bindBankCardList: 'Bind Bank Card List',
    bindCryptoList: 'Bind Crypto List',
    binded: 'Binded',
    bindEWalletList: 'Bind E-Wallet List',
    bindSecurityQn: 'Setup Security Question',
    bindWithdrawPw: 'Setup Withdraw Password',
    bonus: 'Bonus',
    bulk_read: 'Bulk Read',
    bulk_delete: 'Bulk Delete',
    cancel: 'Cancel',
    cardAccount: 'Card Account',
    cardAddress: 'Card Address',
    cardNumber: 'Card Number',
    changeWithdrawPw: 'Change Withdraw Password',
    clearingSum: 'Clearing Sum',
    commission: 'Commission',
    gamecommission: "Platform Commission",
    commissionBalance: 'Commission Balance',
    commissionPercent: 'Commission Percent',
    commissionRate: 'Commission Rate',
    commissionReport: 'Commission Report',
    commissionTransfer: 'Commission Transfer',
    commissionWallet: 'Commission Wallet',
    companyProfit: 'Company Profit',
    confirm: 'Confirm',
    confirmNewPassword: 'Confirm New Password',
    confirmWithdraw: 'Confirm Withdraw',
    copy: 'Copy',
    createAffiliate: 'Create Affiliate',
    createTime: 'Create Time',
    creditFlow: 'Credit Flow',
    creditFlowAmount: 'Credit Flow Amount',
    creditFlowId: 'ID',
    creditFlowType: 'Credit Flow Type',
    creditFlowDate: 'Credit Flow Date',
    creditFlowBalance: 'Credit Flow Balance',
    crypto: 'Crypto',
    currentPassword: 'Current Password',
    deduct: 'Deduct',
    deposit: 'Deposit',
    depositAmount: 'Deposit Amount',
    depositBettingAmount: '存款/下注金额',
    depositCount: 'Deposit Count',
    depositDate: 'Deposit Date',
    depositRecord: 'Deposit Record',
    depositUsers: 'Deposit Users',
    depositWallet: 'Deposit Wallet',
    domainAffiliate: 'Affiliate Domain',
    domainApp: 'PC Domain',
    domainWeb: 'Mobile Domain',
    downlineAffiliate: 'Downline Affiliate',
    downlineCommission: 'Downline Commission',
    downlineCommissionRate: 'Downline Commission Rate',
    downlineMember: 'Downline Member',
    downlineProfit: 'Downline Profit',
    download: 'Download',
    edit: 'Edit',
    editAffiliate: 'Edit Affiliate',
    editRealName: 'Update Real Name',
    email: 'Email',
    endDate: 'End Date',
    enquire: 'Enquire',
    enterTheWithdrawalAmount: 'Enter the withdrawal amount',
    estimatedAffiliateCommission: 'Estimated Affiliate Commission',
    estimatedMemberCommission: 'Estimated Member Commission',
    ewallet: 'e-Wallet',
    finalSum: 'Final Sum',
    finishDate: 'Finish Date',
    firstDepositAmount: 'First Deposit Amount',
    firstDepositUsers: 'First Deposit Users',
    ftd: 'First Deposit',
    gameName: 'Game Name',
    gameType: 'Game Type',
    lastLoginTime: 'Last Login Time',
    lastMonth: 'Last Month',
    lastMonthTotal: 'Last Month Total',
    lastWeek: 'Last Week',
    link: 'Invitation Link',
    loginName: 'Login Name',
    loginPassword: 'Password',
    member: 'Member',
    memberBetRecords: 'Member Bet Records',
    memberCommission: 'Member Commission',
    memberProfitDownlineProfitUnsettleCommission:
      'Member Profit / Downline Profit / Unsettle Commission',
    memberInfo: 'Member Info',
    memberType: 'Member Type',
    minActiveMemberCount: 'Minimum Active Member Count',
    month: 'Month',
    monthlyAffiliateCommission: 'Monthly Affiliate Commission',
    monthBeforeLastTotal: 'Month Before Last Total',
    monthlyMemberCommission: 'Monthly Member Commission',
    newMemberCount: 'New Member Count',
    activeMemberCount: 'Active Member Count',
    totalRegisterCount: 'Total Register Count',
    myAccount: 'My Account',
    realName: 'Real Name',
    name: 'Name',
    netProfit: 'Net Profit',
    newPassword: 'New Password',
    newUsers: 'New Users',
    ngr: 'NGR',
    noData: 'No Data',
    actions: 'Actions',
    operate: 'Operate',
    operationalData: 'Operational Data',
    password: 'Password',
    paymentFee: 'Payment Fee',
    paymentMethod: 'Payment Method',
    paymentName: 'Payment Name',
    payout: 'Payout',
    personal: 'Personal',
    personalInfo: 'Personal Info',
    privilegeSerialNumber: 'Serial Number',
    privilegeRecord: 'Privilege Records',
    privilegeName: 'Privilege Name',
    platform: 'Platform',
    platformFee: 'Platform Fee',
    profit: 'Profit',
    questionOne: 'Question One',
    questionTwo: 'Question Two',
    questionThree: 'Question Three',
    readed: 'Readed',
    rebate: 'Rebate',
    recordTime: 'Record Time',
    reenterPassword: 'Re-enter Password',
    referralCode: 'Referral Code',
    referralLink: 'Referral Link',
    regenerate: 'Regenerate',
    registerTime: 'Register Time',
    reset: 'Reset',
    result: 'Result',
    revenueShare: 'Revenue Share',
    revenueShareRate: 'Revenue Share Rate',
    rollover: 'Rollover',
    search: 'Search',
    secondLevelAffiliateCommission: 'Second Level Affiliate Commission',
    securityInfo: 'Security Info',
    securityQuestion: 'Security Question',
    select: 'Select',
    selectACard: 'Select a card',
    selectBank: 'Select a bank',
    selectBankCard: 'Select bank card',
    selectUsdtWallet: 'Please select a USDT wallet',
    serialNumber: 'Serial Number',
    paymentType: 'Payment Type',
    settleTime: 'Settle Time',
    settleView: 'View',
    settlePay: 'Pay',
    settleEdit: 'Edit',
    site: 'Site',
    startDate: 'Start Date',
    status: 'Status',
    subtotal: 'Sub Total',
    systemAlert: 'System Alert',
    affiliateSuccessSubmit:
      'Dear partner, your information has been successfully submitted. Our agent will inform you of the review result within 24 hours. If you have any questions, please contact our agent or online customer service. Thank you.',
    systemAnnouncement: 'System Announcement',
    telephone: 'Telephone',
    thirdLevelAffiliateCommission: 'Third Level Affiliate Commission',
    thisYear: 'This Year',
    thisThreeMonths: 'Three Months',
    thisMonth: 'This Month',
    thisWeek: 'This Week',
    title: 'Title',
    today: 'Today',
    totalBet: 'Total Bet',
    totalCompanyProfit: 'Total Company Profit',
    totalCommissionProfit: 'Total Commission Profit',
    totalCommission: 'Total Commission',
    totalDeposit: 'Total Deposit',
    totalDownlineAffiliate: 'Total Downline Affiliate',
    totalDownlineMember: 'Total Downline Member',
    totalPayout: 'Total Payout',
    totalTransfer: 'Total Transfer',
    totalWithdraw: 'Total Withdraw',
    transactionId: 'Transaction ID',
    transfer: 'Transfer',
    transferAmount: 'Transfer Amount',
    transferDate: 'Transfer Date',
    transferIn: 'Transfer In',
    transferOut: 'Transfer Out',
    transferRecord: 'Transfer Record',
    transferType: 'Transfer Type',
    transferUsers: 'Transfer Users',
    unread: 'Unread',
    upperName: 'Upper Name',
    usdtWallet: 'USDT Wallet',
    usdtWalletAddress: 'USDT Wallet Address',
    unsettleCommission: 'Unsettled Commission',
    updateBy: 'Update By',
    viewDetails: 'View Details',
    vipLevel: 'VIP Level',
    visitsNo: 'Number of Visits',
    winLoss: 'Win/Loss',
    withdraw: 'Withdraw',
    withdrawAmount: 'Withdraw Amount',
    withdrawCount: 'Withdraw Count',
    withdrawPassword: 'Withdraw Password',
    withdrawPayoutBonus: 'Withdraw Payout Bonus Adjust',
    withdrawalAmount: 'Withdrawal Amount',
    yesterday: 'Yesterday',
    memberTag: 'Member Tag',
    memberTagDescription: 'Please enter description within 10 characters',
    tagSetting: 'Tag Setting',
    createTag: 'Create Tag',
    editTag: 'Edit Tag',
    memberAmount: 'Member Amount',
    delete: 'Delete',
    sequence: 'S/N',
    back: 'Back',
    all: 'All',
    yes: 'Yes',
    no: 'No',
    more: 'More',
    remark: 'Member Remark',
    bonusRecord: 'Bonus Record',
    ftdTime: 'First Deposit Time',
    validBet: 'Valid Bet',
    unAssigned: 'Not Assigned',
    batchEditTag: 'Batch Edit Tag',
    personalReport: 'Personal Report',
    platformFeePercentage: 'Fee Percentage',
    rolloverAmount: 'Rollover',
    privilegeType: 'Privilege Type',
    personalCommission: 'Personal Commission',
    payTime: 'Pay Time',
    payStatus: 'Pay Status',
    accountAdjustment: 'Account Adjustment',
    commissionAdjustment: 'Commission Adjustment',
    image: 'Image',
    imageTitle: 'Image Title',
    imageType: 'Image Type',
    uploadTime: 'Upload Time',
    imageSize: 'Image Size',
    downloadTime: 'No. of Download',
    generateQR: 'Generate QR Code',
    qrCode: 'QR Code',
    textInfo: 'Text Info',
    qrCodeColor: 'QR Code Color',
    qrCodeBgColor: 'QR Code Background Color',
    qrCodeLink: 'QR Code Link',
    qrCodePreview: 'QR Code Preview',
    text: 'Text',
    textColor: 'Text Color',
    textBgColor: 'Text Background Color',
    textPreview: 'Text Preview',
    preview: 'Preview',
    custom: 'Custom',
    packHistory: 'Pack History',
    packType: 'Pack Type',
    selectSystem: 'Select System',
    appVersion: 'App Version',
    appName: 'App Name',
    appIcon: 'App Icon',
    packNow: 'Pack Now',
    upload: 'Upload',
    packDate: 'Pack Date',
    osType: 'System Type',
    buildStatus: 'Build Status',
    apkType: 'APK Type',
    normal: 'Normal',
    newKey: 'Antivirus',
    detail: 'Detail',
    packSize: 'Pack Size',
    packDownloadUrl: 'Channel Pack Download Url',
    unchanged: 'Unchanged',
    unbind: 'Unbind',
    depositWithdrawalProfit: 'Deposit Withdrawal Profit',
    registerCount: 'Register Count',
    ftdCount: 'FTD Count',
    ftdAmount: 'FTD Amount',
    indBet: 'Bet',
    indProfit: 'Profit',
    last3Months: 'Last 3 Months',
    total: 'Total',
    betAmount: 'Bet Amount',
    payoutAmount: 'Payout Amount',
    totalMemberDepositAmount: 'Total Member Deposit Amount',
    totalMemberDepositCount: 'Total Member Deposit Count',
    bonusAmount: 'Bonus Amount',
    rebateAmount: 'Rebate Amount',
    indBonusAmount: 'Bonus Amount',
    indRebateAmount: 'Rebate Amount',
    indAdjustAmount: 'Adjust Amount',
    grossProfit: 'Gross Profit',
    totalBetMemberCount: 'Total Member Bet Count',
    totalDepositMemberCount: 'Total Member Deposit Count',
    orderBy: 'Order By',
    sortType: 'Sort Type',
    exclusiveDomain: 'Exclusive Domain Name',
    pcWay: 'PC',
    mobileWay: 'Mobile',
    forgetPassword: 'Forget Password',
    authenticator: 'Authenticator',
    createMember: 'Create Member',
    shareRatio: 'Share Ratio',
    rebateWallet: 'Rebate Wallet',
    affiliateTotalRebate: 'Affiliate Rebate',
    myRebate: 'My Rebate',
    totalWinLoss: 'Total Win/Loss',
    todayDeposit: 'Today Deposit',
    todayWithdrawal: 'Today Withdrawal',
    depositWithdrawalDiff: 'Deposit Withdrawal Difference',
    recommenderCode: 'Recommender Code',
    memberShareRatio: 'Member Share Ratio',
    editShareRatio: 'Edit Share Ratio',
  },
  google: {
    google_auth_menu: 'Google Authenticator',
    download_install: 'Download App',
    add_key: 'Add Security Key',
    bind_verify: 'Bind Google Auth',
    popup_title: 'Google Authenticator',
    popup_msg:
      "A Google Authenticator is a dynamic password tool that automatically refreshes every 30 seconds. When performing sensitive actions such as transferring funds, making withdrawals, or adjusting security settings in the proxy's backend, you need to input this 6-digit identity verification code for authentication.",
    popup_hint:
      'The identity authenticator must be used in conjunction with the proxy account.',
    i_know: 'I Know',
    download_way: 'Way to Download：',
    download_through_way: '1.Via Download Link：',
    ios_download: 'iOS Download',
    android_download: 'Android Download',
    ios_scan_download: 'Scan IOS Download',
    android_scan_download: 'Scan Android Download',
    if_cannot_download_you_can:
      '2.If you cannot download it, you can search for it on the Apple App Store."',
    or_search_in_google: '", or search on the Android App Store.',
    download_and_install: '" to download & install。',
    next_step: 'Next',
    keyin_pass: 'Key In Password & Next',
    keyin_your_password: 'Enter Login Password',
    tips_installed:
      'Quick tip: Once it\'s downloaded and installed, simply click "Next" to proceed.',
    qr_code: 'QR Code',
    secret_key: 'Secret Key',
    add_step: 'Steps to add',
    add_step_desc:
      'Open Google Authenticator, click on the bottom right corner\'s "+" sign, select "Manually enter the key," and enter any account to bind it with the above-mentioned secret key.  (Scanning the QR code can automatically add it.)',
    check_example: 'View Example',
    back: 'Back',
    small_tips: 'Quick tip:',
    small_tips_info_1:
      '1. If you lose your phone or uninstall the authentication app, the key can help you recover the authenticator. Please keep it safe.',
    small_tips_info_2:
      '2.For the security of your account, please do not mark the proxy account and proxy backend address during the binding process.',
    keyin_6_digit_google: 'Please key in Google 6 digits Auth Code.',
    auth_code: 'Google Authenticator',
    bind_now: 'Bind Now',
    please_enter_password: 'Please enter your password',
    added_success: 'Google Authenticator code added successfully',
    if_not_google_auth_then:
      "If you haven't bound Google Authenticator, there's no need to fill it out.",
    google_auth_code: 'Enter 6 digits Google Authenticator Code',
  },
  forgetPassword: {
    verifyAuth: 'Authentication',
    verifyQues: 'Security Question',
    resetPassword: 'Reset Password',
    verify: 'Verify',
    submit: 'Submit',
    reset: 'Reset',
    answer: 'Answer',
    messageAuth: 'Please enter login name and google authentication code',
    messageQues: 'Please answer the following security question',
    messageReset: 'Please reset your password',
    noSecurityQuestionSet:
      'This account does not setup security question, please contact customer support',
    resetSuccess: 'Password reset successfully',
  },
  message: {
    adjustSuccess: 'Adjust Success',
    addSuccess: 'Add Success',
    bindBankCard: 'Please bind bank card first',
    bindUsdtWallet: 'Please bind USDT wallet first',
    chineseCharacters: 'Please input chinese characters',
    commissionPaySuccess: 'Commission Pay Success',
    confirmToAdjust:
      'Are you sure to make adjustment? Unable to make other adjustment after confirmation.',
    confirmToPay: 'Confirm that you want proceed to payment?',
    deleteSuccess: 'Delete Successfully',
    domainAppCopied: 'Web Domain has been copied to clipboard.',
    domainWebCopied: 'App Domain has been copied to clipboard.',
    editSuccess: 'Edit Success',
    inputRealName: 'Member real name has not been set',
    emailFormat: 'Please input the correct email address format',
    inputChineseCharacters: 'Please input chinese characters',
    inputDigits: 'Please input digits',
    inputPassword: 'Please input the password',
    inputPasswordAgain: 'Please input the password again',
    length6To12: 'Length should be 6 to 12',
    lengthShouldBe: 'Length should be ',
    passwordLength:
      'The password cannot be less than 6 digits or more than 12 digits',
    redirectBankDeposit:
      'You have been redirected to your specific bank to proceed with the deposit. Once the deposit is successful, it will be reflected here.',
    reenterPassword: 'Please re-enter the password',
    referralLinkCopied: 'Referral link has been copied to clipboard.',
    remaining: 'Remaining',
    requiredAffiliateLevel: 'Affiliate level is required',
    required_signup_account: 'Agency account cannot be empty.',
    required_6_to_12: 'Must be composed of 6-12 digits or letters.',
    requried_password: 'Login password cannot be empty.',
    required_captcha: 'Verification code cannot be empty.',
    required_4_digits: 'Must be composed of 4 digits.',
    required_confirm_pwd: 'Password confirmation cannot be empty.',
    required_same_with_password: 'Must be consistent with the login password.',
    requiredAmount: 'Amount is required',
    requiredAnswer: 'Answer is required',
    requiredCardAccount: 'Card account is required',
    requiredCardAddress: 'Card address is required',
    requiredCardNumber: 'Please enter the bank card number',
    requiredCommission: 'Commission is required',
    requiredEmail: 'Email is required',
    requiredLoginName: 'Login name is required',
    requiredMemberType: 'Please select member type.',
    requiredOldPassword: 'Current Password is required',
    requiredPassword: 'Password is required',
    requiredRealName: 'Real name is required',
    requiredRevenueShare: 'Revenue Share is required',
    requiredRollover: 'Rollover is required',
    requiredTelephone: 'Telephone is required',
    requiredTransferAmount: 'Transfer Amount is required',
    requiredUsdtWallet: 'Please enter the USDT wallet',
    requiredUserName: 'User name is required',
    requiredWithdrawPassword: 'Withdraw Password is required',
    selectAQuestion: 'Please select a question',
    selectBankCard: 'Please select a bank card',
    selectUsdtWallet: 'Please select a USDT wallet',
    setSecurityQn: 'Please setup security question',
    setSecAndPw: 'Please setup security question and withdraw password',
    setWithdrawPassword: 'Please setup withdraw password',
    singleLimit: 'Single Limit',
    success: 'Success',
    times: 'times',
    twoPasswordNotMatch: 'Two password does not match',
    unbindConfirm: 'Are you sure you want to unbind ',
    validateAdjustAmountRequired: 'Adjust Amount is required',
    validateAdjustReasonRequired: 'Adjust Reason is required',
    validateAdjustTypeRequired: 'Adjust Type is required',
    validateBankCardNumber: 'Only number is accepted for bank card number',
    validateCommission: 'Commission must be between 0 to 1',
    validateNumberOnly: 'Please enter number only',
    validateNumberMoreThanOne: 'Please enter number more than 1',
    validateRevenueShare: 'Revenue Share must be between 0 to 1',
    validateUsdtWallet: 'Only alphabet and number is accepted for USDT wallet',
    withdrawalToday: 'Withdrawal Today',
    inputUSDTAmount: 'Please input USDT amount',
    inputAmount: 'Please input amount',
    selectAmount: 'Please select amount',
    minDepositeAmount: 'Minimum deposit',
    maxDepositeAmount: 'Maximum deposit',
    currencyRates: 'Currency Rates',
    understand: 'Understand',
    DepositCompleted: 'Completed',
    depositNotification1:
      "You will be redirected to your bank's page to complete the deposit.",
    depositNotification2:
      'If successful, you will receive a notification on this page.',
    validateTagDescriptionRequired: 'Tag Description is required',
    confirmDelete:
      'Confirm that you want to delete these data, the operation cannot be undone',
    remarkMessage: 'Please enter the member remark within 200 characters.',
    validateTagDescriptionLength: 'Tag Description length must be 1 to 10',
    packRequestInQueue: 'Your channel pack request is in queue',
    channelPackCancelSuccess: 'Your channel pack is cancelled successfully',
    channelPackUrlCopied: 'Channel Pack Url has been copied to clipboard.',
    validateAppNameRequired: 'App Name is required',
    validateAppIconRequired: 'App Icon is required',
    validateShareRatioFormat: 'Share Ratio must be between 0 to 1',
    depositShouldBetween: 'Deposit amount should be between ',
    requiredPositiveInteger: 'Required Positive Integer',
  },
  menu: {
    undefined: '',
    Dashboard: 'Dashboard',
    'Downline Info': 'Downline Info',
    Member: 'Member',
    Affiliate: 'Affiliate',
    AffiliateSummary: 'Affiliate Summary',
    gameRecord: 'Game Record',
    financeCentre: 'Finance Centre',
    'Bet Record': 'Member Bet Record',
    'Deposit Record': 'Member Deposit Record',
    Statistics: 'Statistics',
    'Game Stats': 'Game Stats',
    'Affiliate Daily Report': 'Affiliate Daily Report',
    'Affiliate Center': 'Affiliate Center',
    Deposit: 'Deposit',
    'Bind Bank Cards': 'Bind Bank Cards',
    'Bank Withdrawal': 'Bank Withdrawal',
    Transfer: 'Transfer',
    'Referral Centre': 'Referral Centre',
    'Referral Management': 'Referral Management',
    'Referral Link': 'Referral Link',
    'Referral Material': 'Referral Material',
    'Affiliate Domain': 'Affiliate Domain',
    'Personal Center': 'Personal Center',
    'Settlement Center': 'Settlement Center',
    'Finance Report': 'Finance Report',
    'Settlement Report': 'Settlement Report',
    'Channel Pack': 'Channel Pack',
    contactUs: 'Contact Us',
    'Daily Detail': 'Daily Detail',
    'Daily Summary': 'Daily Summary',
    Withdraw: 'Withdraw',
    'Rebate Report': 'Rebate Report',
  },
  posterType: {
    OVERALL: 'Overall Referral',
    APP: 'APP Referral',
    SPONSOR: 'Sponsor Referral',
    GIFT: 'Gift Referral',
    COMPETITION: 'Competition Referral',
    EVENT: 'Event Referral',
    CRYPTO: 'Crypto Referral',
    AFFILIATE: 'Affiliate Benefit',
  },
  packStatus: {
    IN_QUEUE: 'In Queue',
    IN_PROGRESS: 'In Progress',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    CANCEL: 'Cancel',
  },
  appType: {
    ALL_SITE: 'ALL SITE',
    SPORT: 'SPORT',
    ESPORT: 'ESPORT',
  },
  member: {
    status: {
      normal: 'NORMAL',
      frozen: 'FROZEN',
    },
  },
  affiliate: {
    level: {
      JUNIOR_AFFILIATE: 'Junior Affiliate',
      SUB_AFFILIATE: 'Sub Affiliate',
      AFFILIATE: 'Affiliate',
      SUPER_AFFILIATE: 'Super Affiliate',
      MASTER_AFFILIATE: 'Master Affiliate',
      CHIEF_AFFILIATE: 'Chief Affiliate',
    },
    status: {
      APPLY: 'Apply',
      NORMAL: 'Normal',
      DISABLE: 'Disable',
    },
  },
  osType: {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: 'Bet Ratio-FISH',
    AGENT_SHARE_BET_RATIO_SLOT: 'Bet Ratio-SLOT',
    AGENT_SHARE_BET_RATIO_ESPORT: 'Bet Ratio-ESPORT',
    AGENT_SHARE_BET_RATIO_LIVE: 'Bet Ratio-LIVE',
    AGENT_SHARE_BET_RATIO_SPORT: 'Bet Ratio-SPORT',
    AGENT_SHARE_PROFIT_RATIO_FISH: 'Profit Ratio-FISH',
    AGENT_SHARE_PROFIT_RATIO_SLOT: 'Profit Ratio-SLOT',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: 'Profit Ratio-ESPORT',
    AGENT_SHARE_PROFIT_RATIO_LIVE: 'Profit Ratio-LIVE',
    AGENT_SHARE_PROFIT_RATIO_SPORT: 'Profit Ratio-SPORT',
  },
  error: {
    400: 'Bad Request',
    403: 'Forbidden',
    405: 'Method Not Allowed',
    500: 'System Error',
    501: 'Duplicate Request ID',
    502: 'Server Not Found',
    504: 'Too Often Request',
    601: 'Token verification error',
    602: 'Token has expired',
    603: 'Token is missed',
    604: 'Account already login',
    707: 'Insufficient balance',
    709: 'Insufficient commission balance',
    800: 'Captcha verification error',
    801: 'Captcha code has expired',
    901: 'Affiliate record does not exists',
    902: 'Member is not an affiliate',
    903: 'Account is an affiliate',
    904: 'Account is a member',
    905: 'Real name has not been set',
    1000: 'Login name or password is error',
    1001: 'Login account is disable',
    1100: 'New password cannot be the same as your old password',
    1101: 'Old password incorrect',
    1102: 'Withdraw password is incorrect',
    1201: 'Answer is incorrect',
    1308: 'Balance is not enough',
    1311: 'Do not submit same withdraw amount within 24 hours',
    13000: 'Domain name does not exist',
    14000: 'Duplicate login name : ',
    14001: 'Duplicate phone number : ',
    14002: 'Duplicate email : ',
    14003: 'Affiliate code does not exists : ',
    14004: 'Affiliate code does not belong to this site : ',
    14005: 'Affiliate commission must be less than superior affiliate commission : ',
    14006: 'Affiliate revenue must be less than superior affiliate revenue : ',
    14007: 'Affiliate application is not being approved yet',
    14008: 'Affiliate commission must be more than child affiliate commission : ',
    14009: 'Affiliate revenue must be more than child affiliate revenue : ',
    14100: 'This member is not your downline member',
    14102: 'Member tag already exist',
    14103: 'Member tag limit exceeded',
    15201: 'Old password incorrect',
    15202: 'New password cannot be the same as your old password',
    14106: 'Password incorrect',
    24000: 'Verification Failed',
    1009: 'Incorrect Google authentication code',
    900: 'Account does not exist',
    11011: 'Superior share ratio not set',
    11012: 'Superior share ratio not set for game type: ',
    11013: 'Affiliate share ratio cannot exceed superior ratio',
    11014: 'Affiliate share ratio total for all downlines cannot exceed superior affiliate share ratio',
    11015: 'Affiliate share ratio not set',
    11016: 'Member share ratio cannot exceed affiliate share ratio',
    14012: 'Can only create affiliate with lower level',
    14013: 'Affiliate has downline, cannot set to lower level',
  },
}
